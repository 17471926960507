






























import { Vue, Component } from "vue-property-decorator";
import { $transaction } from "@/services/TransactionService";
import { $overlay } from "@/services/OverlayService";
import { $validation } from "@/services/ValidationService";

import Logo from "@/components/Logo.vue";
import Info from "@/components/Info.vue";
import Form from "@/components/Form.vue";

@Component({
  components: {
    "ag-logo": Logo,
    "ag-info": Info,
    "ag-form": Form,
  },
})
export default class Challenge extends Vue {
  get overlay() {
    return $overlay.show;
  }

  get challenge() {
    return $validation.challenge;
  }

  get validForm() {
    return $validation.validForm;
  }

  async validate() {
    if ($validation.challengeData) {
      $overlay.show = true;
      const resp = await $validation.answerChallenge();
      if (resp == null) {
        if ($validation.errorCode == "000") {
          this.$router.push({
            path: "/success",
          });
        } else {
          // GO TO ERROR PAGE
          this.$router.push({
            path: "/error",
            query: { status: "challenge_failed" },
          });
        }
        $overlay.show = false;
      } else {
        window.location.reload();
      }
    }
  }

  async mounted() {
    const tx = $transaction.transaction;
    if (tx != null) {
      const challenge = await $validation.loadChallenge(tx.id);
      if (!challenge) {
        if ($validation.errorCode == "000") {
          this.$router.push({
            path: "/success",
          });
        } else {
          // GO TO ERROR PAGE
          this.$router.push({
            path: "/error",
            query: { status: "challenge_expired" },
          });
        }
        $overlay.show = false;
      } else {
        if (challenge.redirect_url) {
          window.location.href = challenge.redirect_url;
        } else {
          $overlay.show = false;
        }
      }
    } else {
      // GO TO ERROR PAGE
      this.$router.push({
        path: "/error",
        query: { status: "tx_not_provided" },
      });
    }
  }
}
