/* eslint-disable @typescript-eslint/no-this-alias */

/**
 * A function that emits a side effect and does not return anything.
 */
export type Procedure = (...args: any[]) => void;
/**
 * Options for debounce function
 */
export type Options = {
  /* Executes immediatly on call */
  isImmediate: boolean;
};
/**
 * Executes a function and avoids all subsequent executions
 * for a period of time.
 *
 * @param func
 * @param waitMilliseconds
 * @param options
 */
export function debounce<F extends Procedure>(
  func: F,
  waitMilliseconds = 50,
  options: Options = {
    isImmediate: false,
  }
): (this: ThisParameterType<F>, ...args: Parameters<F>) => void {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;

  return function (this: ThisParameterType<F>, ...args: Parameters<F>) {
    const context = this;

    const doLater = function () {
      timeoutId = undefined;
      if (!options.isImmediate) {
        func.apply(context, args);
      }
    };

    const shouldCallNow = options.isImmediate && timeoutId === undefined;

    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(doLater, waitMilliseconds);

    if (shouldCallNow) {
      func.apply(context, args);
    }
  };
}
/**
 * Uses regex to replace invalid symbols from a given RUN. Returns a clean RUN
 * string in lower case.
 *
 * @param rut
 * @returns
 */
export function cleanRut(rut: string) {
  return rut.replace(/[^0-9kK]+/g, "").toLowerCase();
}
/**
 * Calculates the verifying number of the RUN and compares it with the one
 * provided.
 *
 * @param rut
 * @returns
 */
export function validateRut(rut: string) {
  const numberRut = cleanRut(rut).slice(0, -1);
  if (numberRut.length > 6) {
    const auxArray = [3, 2, 7, 6, 5, 4, 3, 2];
    let sum = 0;

    for (let i = numberRut.length - 1; i >= 0; i--) {
      sum += parseInt(numberRut[i]) * auxArray[i];
    }
    switch (11 - (sum % 11)) {
      case 11:
        return rut.slice(-1) == "0";
      case 10:
        return rut.slice(-1) == "k" || rut.slice(-1) == "K";
      default:
        return rut.slice(-1) == (11 - (sum % 11)).toString();
    }
  }
  return false;
}
/**
 * All available textfield rules for input validation.
 */
export const rules = {
  required: (val: any) => !!val || "Campo requerido.",
  isNumber: (val: any) =>
    !isNaN(Number(val)) || "Solo se permiten valores numéricos.",
  isPositive: (val: number) =>
    val > 0 || "Solo se permiten números mayores a cero.",
  validRut: (val: string) =>
    validateRut(val || "") || "Debes ingresar un RUN válido",
  withHyphen: (val: string) =>
    (!!val && val.includes("-")) ||
    "Debes agregar el guión antes del dígito verificador.",
};
