




import { $validation } from "@/services/ValidationService";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class CuTocForm extends Vue {
  get challenge() {
    return $validation.challenge;
  }

  mounted() {
    if (this.challenge && this.challenge.redirect_url) {
      window.location.href = this.challenge.redirect_url;
    }
  }
}
