










import { $whitelabel } from "@/services/WhitelabelService";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class Logo extends Vue {
  get image() {
    const wl = $whitelabel.getState().whitelabel;
    return wl ? "data:image/png;base64," + wl.logo : null;
  }
}
