




























import { $validation } from "@/services/ValidationService";
import { Vue, Component, Prop } from "vue-property-decorator";

import CuTocForm from "./validations/cu_toc/Form.vue";
import RegcivilForm from "./validations/regcivil/Form.vue";

@Component({
  components: {
    "ag-regcivil-form": RegcivilForm,
    "ag-cu-toc-form": CuTocForm,
  },
})
export default class Form extends Vue {
  get service() {
    return $validation.challenge?.service_name;
  }

  get valid() {
    return $validation.validForm;
  }

  set valid(value: boolean) {
    $validation.validForm = value;
  }
}
