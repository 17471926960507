























































import { rules } from "@/services/core/Utils";
import { $validation } from "@/services/ValidationService";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class RegcivilForm extends Vue {
  get showDni() {
    return Object.keys($validation.challengeData)?.includes("dni");
  }

  get showDocumentNumber() {
    return Object.keys($validation.challengeData)?.includes("document_number");
  }

  get dni() {
    return $validation.challengeData["dni"];
  }

  set dni(value) {
    $validation.challengeData = {
      ...$validation.challengeData,
      ...{ dni: value },
    };
  }

  get documentNumber() {
    return $validation.challengeData["document_number"];
  }

  set documentNumber(value) {
    $validation.challengeData = {
      ...$validation.challengeData,
      ...{ document_number: value },
    };
  }

  get challenge() {
    return $validation.challenge;
  }

  get rules() {
    return rules;
  }
}
