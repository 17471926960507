import Service from "./core/Service";
import { Challenge, Schema } from "agilews-lib";
import { $api } from "./ApiService";

type ValidationState = {
  challenge: Challenge | null;
  challengeData: Record<string, unknown>;
  validForm: boolean;
  errorCode: string | null;
};

class ValidationService extends Service<ValidationState> {
  protected state: ValidationState = {
    challenge: null,
    challengeData: {},
    validForm: false,
    errorCode: null,
  };

  get challenge() {
    return this.state.challenge;
  }

  get challengeData() {
    return this.state.challengeData;
  }

  set challengeData(value) {
    this.setState({ challengeData: value });
  }

  get validForm() {
    return this.state.validForm;
  }

  set validForm(value: boolean) {
    this.setState({ validForm: value });
  }

  get errorCode() {
    return this.state.errorCode;
  }

  private initChallengeData(schema: Schema = {}) {
    this.challengeData = {};
    for (const key in schema) {
      this.challengeData[key] = null;
    }
  }

  public async loadChallenge(transaction_id: string) {
    const resp = await $api.client.transactions.startChallenge(transaction_id);
    if (resp.error) {
      if (resp.error.error.code) this.state.errorCode = resp.error.error.code;
      this.state.challenge = null;
    } else {
      this.state.challenge = resp.data;
    }
    this.initChallengeData(resp.data?.schema);
    return this.state.challenge;
  }

  public async answerChallenge() {
    if (this.state.challenge) {
      const resp = await $api.client.transactions.anwserChallenge(
        this.state.challenge.transaction_id,
        this.state.challenge.id,
        { data: this.state.challengeData }
      );

      if (resp.error) {
        this.state.challenge = null;
      } else {
        if (resp.data) {
          if (resp.data.next_challenge) {
            this.state.challenge = resp.data.next_challenge;
            this.state.errorCode = null;
            this.state.validForm = false;
          } else {
            if (resp.data.success) {
              this.state.errorCode = "000";
            }
            this.state.challenge = null;
          }
        } else {
          this.state.challenge = null;
        }
      }
      this.initChallengeData(resp.data?.next_challenge?.schema);
    }

    return this.state.challenge;
  }
}

export const $validation = Service.singleton(ValidationService);
