







import { $validation } from "@/services/ValidationService";
import { Vue, Component, Prop } from "vue-property-decorator";
import RegcivilInfo from "./validations/regcivil/Info.vue";

@Component({
  components: {
    "ag-regcivil-info": RegcivilInfo,
  },
})
export default class Info extends Vue {
  get service() {
    return $validation.challenge?.service_name;
  }
}
